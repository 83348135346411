<template>
  <div class="misc-wrapper w-100">
    <b-link class="brand-logo">
      <vuexy-logo />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Just a moment we're checking your authorization...
        </h2>
        <p class="mb-2">Please wait a second</p>
        <b-img fluid :src="imgUrl" alt="Not authorized page" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BLink,
    BImg,
    BButton,
    VuexyLogo,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/not-authorized.svg"),
      token: this.$route.params.token,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/not-authorized-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
  methods: {
    loginRoute() {
      const user = JSON.parse(localStorage.getItem("userData"));
      return getHomeRouteForLoggedInUser(user ? user.role : null);
    },
  },
  created() {
    
    // here we need to handle tokens differently
    const token = `Bearer ${this.token}`
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    }

    this.$http.get('/api/auth/user', requestOptions)
      .then(response => {
        const userData = response.data
        userData.accessToken = this.token
        userData.token_type = 'Bearer'
        userData.avatar = this.makeAvatar(userData.username)
        // Set user data in localstorage
        localStorage.setItem('userData', JSON.stringify(userData))
        store.commit("app/setUserData", userData);

        if(this.$route.query.slug != undefined) {
          // if there is a slug param, redirect to the corresponding questionnaire
          localStorage.setItem("questionnaire_completed", 1)
          let slug_param = this.$route.query.slug;
          setTimeout(() => {
            this.$router.push('/form/' + slug_param);            
          }, 1500);
        } else {
          // else, redirect to Home screen
          setTimeout(() => {
            this.$router.push('/my-hub')        
          }, 1500);
        }
        
      })
      .catch(error => {
        console.log(error)

        this.$toast({
          component: ToastificationContent,
          props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
          },
        });
      })
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
